<template>
  <div class="civilian">
    <InformationBanner></InformationBanner>
    <div class="_container">
      <Arrestation
        v-if="civilian"
        :parent="true"
        :title="$t('civilian.arrestation')"
        page="civilian"
        name="arrestation"
        :width="800"
        :height="500"
        :issuer="civilian.id"
        :action="'create'"
        :defendant="civilian.id"
        :disableCivilianSearch="true"
      ></Arrestation>
      <Violation
        v-if="civilian"
        :parent="true"
        :title="$t('civilian.violation')"
        page="civilian"
        name="violation"
        :width="800"
        :height="500"
        :issuer="civilian.id"
        :action="'create'"
        :defendant="civilian.id"
        :disableCivilianSearch="true"
      ></Violation>
      <div class="top">
        <div class="grid">
          <div class="column sign-out">
            <button
              class="orange-full v-step-logout-button"
              @click="$router.push({ name: 'characters' })"
            >
              <span uk-icon="icon: sign-out"></span>
            </button>
          </div>
          <div class="column other left"></div>
          <div class="column logo">
            <img
              src="//cdn.leafcad.com/images/logos/leafcad-logos/Logo%20LeafCAD.png"
              alt="community-logo"
            />
          </div>
          <div class="column other right"></div>
          <div class="column duty-board">
            <div class="version">
              <p class="orange">{{ $t('public-version') }}</p>
            </div>
            <div class="interface-name">
              <h2 class="primary">{{ $t('civilian.civilian') }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="_content">
        <div class="limited col-pc-displaynone">
          <div class="banner warning">
            <p>{{ $t('civilian.limited-functionalities') }}</p>
          </div>
        </div>
        <div class="ad">
          <Adsense
            ins-style="display:inline-block;width:728px;height:90px"
            data-ad-client="ca-pub-7037140739891615"
            data-ad-slot="4424682433"
            class="col-mdmobile-displaynone col-smobile-displaynone"
          >
          </Adsense>
        </div>
        <div class="top-row">
          <div
            class="connected-as"
            v-if="civilian"
          >
            <div class="mobile col-pc-displaynone">
              <i class="fas fa-user"></i>
              <p class="name">{{ `${selectedCivilian.firstName} ${selectedCivilian.lastName}` }}</p>
              <p class="info">
                {{ moment(selectedCivilian.birthDate).format('YYYY-MM-DD') }} ({{
                  moment().diff(moment(selectedCivilian.birthDate), 'years') + ' ' + $t('civilian.years-old')
                }})
              </p>
            </div>
            <div class="pc col-mobile-displaynone">
              <p>
                {{ $t('civilian.connected-as') }}:
                <b>
                  {{ `${selectedCivilian.firstName} ${selectedCivilian.lastName}` }} -
                  {{ moment(selectedCivilian.birthDate).format('YYYY-MM-DD') }}
                </b>
                ({{ moment().diff(moment(selectedCivilian.birthDate), 'years') + ' ' + $t('civilian.years-old') }})
              </p>
            </div>
          </div>
          <div
            class="mobile-buttons col-lgmobile-10 col-pc-displaynone"
            v-if="checkCurrentPermissions()('civilian')"
          >
            <div class="mobile mobile-button edit-button col-lgmobile-6 col-pc-displaynone">
              <button
                class="secondary-full col-mobile-10"
                :class="{ active: currentTab === '#edit' }"
                @click="currentTab = '#edit'"
              >
                {{ $t('actions.edit') }}
              </button>
            </div>
            <div class="mobile mobile-button change-button col-lgmobile-6 col-pc-displaynone">
              <button
                class="secondary-full col-mobile-10"
                @click="$router.push({ name: 'characters' })"
              >
                {{ $t('actions.change') }}
              </button>
            </div>
          </div>
          <div
            class="change"
            v-if="checkCurrentPermissions()('civilian')"
          >
            <div class="pc col-mobile-displaynone v-step-change-button">
              <button
                class="secondary"
                @click="$router.push({ name: 'characters' })"
              >
                {{ $t('actions.change') }}
              </button>
            </div>
          </div>
        </div>
        <div class="buttons-row buttons-grid">
          <template v-if="checkCurrentPermissions()('civilian')">
            <button
              class="secondary-full v-step-license-button"
              :class="{ active: currentTab === '#licenses' }"
              @click="currentTab = '#licenses'"
            >
              <span class="col-mobile-displaynone">{{ $t('civilian.license') }}(s)</span>
              <i class="fas fa-id-card col-pc-displaynone"></i>
            </button>
            <button
              class="secondary-full v-step-vehicle-button"
              :class="{ active: currentTab === '#vehicles' }"
              @click="currentTab = '#vehicles'"
            >
              <span class="col-mobile-displaynone">{{ $t('civilian.vehicle') }}(s)</span>
              <i class="fas fa-car col-pc-displaynone"></i>
            </button>
            <button
              class="secondary-full"
              disabled
            >
              <span class="col-mobile-displaynone">{{ $t('civilian.firearm') }}(s)</span>
              <i class="lc lc-gun col-pc-displaynone"></i>
            </button>
            <button
              class="secondary-full v-step-crimes-button"
              :class="{ active: currentTab === '#crimes' }"
              @click="currentTab = '#crimes'"
            >
              <span class="col-mobile-displaynone">{{ $t('civilian.crime') }}(s)</span>
              <i class="fas fa-gavel col-pc-displaynone"></i>
            </button>
            <button
              class="secondary-full pc-only v-step-edit-button"
              :class="{ active: currentTab === '#edit' }"
              @click="currentTab = '#edit'"
            >
              <span class="col-mobile-displaynone">{{ $t('civilian.edit-civilian') }}</span>
            </button>
          </template>
        </div>
        <div class="in-content-ad ad">
          <Adsense
            ins-style="display:block"
            data-ad-client="ca-pub-7037140739891615"
            data-ad-slot="6198070746"
            data-ad-format="auto"
            data-full-width-responsive="true"
            class="col-pc-displaynone col-lgmobile-displaynone"
          >
          </Adsense>
        </div>
        <div class="content-row">
          <div
            class="licenses col-pc-12 col-mobile-12"
            v-if="currentTab === '#licenses'"
          >
            <div class="row">
              <div class="columns col-pc-11 col-mobile-11">
                <div class="column">
                  <div class="license-name">
                    <div class="line"></div>
                    <p>{{ $t('civilian.driver-license') }}</p>
                    <div class="line"></div>
                  </div>
                  <div
                    class="rows"
                    v-if="civilian"
                  >
                    <div class="row col-pc-12 col-mobile-12">
                      <p class="title col-pc-4 col-mobile-2">{{ $t('civilian.status') }}:</p>
                      <div class="choice col-pc-6">
                        <vSelect
                          @input="updateDriverLicense('status', $event)"
                          v-model="civilian.driverLicense.status"
                          :reduce="status => status.value"
                          class="custom-select primary font-weight-semibold uppercase col-pc-12"
                          :class="{
                            success: civilian.driverLicense.status == 'VALID',
                            warning: civilian.driverLicense.status === 'SUSPENDED',
                            danger: ['EXPIRED', 'REVOKED', 'NONE'].includes(civilian.driverLicense.status)
                          }"
                          :clearable="false"
                          :options="[
                            { label: $t('civilian.driver-license-valid'), value: 'VALID' },
                            { label: $t('civilian.driver-license-expired'), value: 'EXPIRED' },
                            { label: $t('civilian.driver-license-suspended'), value: 'SUSPENDED' },
                            { label: $t('civilian.driver-license-revoked'), value: 'REVOKED' },
                            { label: $t('civilian.driver-license-none'), value: 'NONE' }
                          ]"
                        ></vSelect>
                      </div>
                      <div class="info">
                        <i
                          class="fas fa-question-circle"
                          :uk-tooltip="'title:' + $t('civilian.driver-license-validity') + ';' + 'pos: right'"
                        ></i>
                      </div>
                    </div>
                    <div class="row checkboxes col-pc-12 col-mobile-12">
                      <p class="title">{{ $t('civilian.driver-license-classes') }}:</p>
                      <div
                        class="choice"
                        v-if="licenses.driver.classes.length > 0"
                      >
                        <div
                          class="choice-box noselect"
                          v-for="licenseClass in licenses.driver.classes"
                          :key="licenseClass.id"
                        >
                          <div
                            class="box"
                            :uk-tooltip="licenseClass.description ? licenseClass.description : 'cls: col-display-none'"
                          >
                            <label
                              :for="'licenseClass-' + licenseClass.id"
                              :class="{
                                active: civilian.driverLicense.classes
                                  .map(licenseClass => licenseClass.id)
                                  .includes(licenseClass.id)
                              }"
                            >
                              <span>{{ licenseClass.name }}</span>
                            </label>
                            <input
                              type="checkbox"
                              :id="'licenseClass-' + licenseClass.id"
                              @change="
                                updateDriverLicense('classes', { id: licenseClass.id, checked: $event.target.checked })
                              "
                              v-model="civilian.driverLicense.classes"
                              :value="{ id: licenseClass.id, __typename: 'CommunityDriverLicenseClass' }"
                            />
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <p>{{ $t('civilian.driver-license-classes-no-classes') }}</p>
                      </div>
                      <div class="info">
                        <i
                          class="fas fa-question-circle"
                          :uk-tooltip="
                            'title:' +
                              $t('civilian.driver-license-classes-description-coming-soon') +
                              ';' +
                              'pos: right'
                          "
                        ></i>
                      </div>
                    </div>
                    <div class="row checkboxes col-pc-12 col-mobile-12">
                      <p class="title">{{ $t('civilian.driver-license-conditions') }}:</p>
                      <div
                        class="choice"
                        v-if="licenses.driver.conditions.length > 0"
                      >
                        <div
                          class="choice-box noselect"
                          v-for="licenseCondition in licenses.driver.conditions"
                          :key="licenseCondition.id"
                        >
                          <div
                            class="box"
                            :uk-tooltip="
                              licenseCondition.description ? licenseCondition.description : 'cls: col-display-none'
                            "
                          >
                            <label
                              :for="'licenseCondition-' + licenseCondition.id"
                              :class="{
                                active: civilian.driverLicense.conditions
                                  .map(licenseCondition => licenseCondition.id)
                                  .includes(licenseCondition.id)
                              }"
                            >
                              <span>{{ licenseCondition.name }}</span>
                            </label>
                            <input
                              type="checkbox"
                              :id="'licenseCondition-' + licenseCondition.id"
                              @change="
                                updateDriverLicense('conditions', {
                                  id: licenseCondition.id,
                                  checked: $event.target.checked
                                })
                              "
                              v-model="civilian.driverLicense.conditions"
                              :value="{ id: licenseCondition.id, __typename: 'CommunityDriverLicenseCondition' }"
                            />
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <p>{{ $t('civilian.driver-license-conditions-no-conditions') }}</p>
                      </div>
                      <div class="info">
                        <i
                          class="fas fa-question-circle"
                          :uk-tooltip="
                            'title:' +
                              $t('civilian.driver-license-conditions-description-coming-soon') +
                              ';' +
                              'pos: right'
                          "
                        ></i>
                      </div>
                    </div>
                    <div class="row datepicker col-pc-12 col-mobile-12">
                      <p class="title">{{ $t('civilian.driver-license-issued') }}:</p>
                      <div class="choice col-pc-6">
                        <div class="ccustom-input primary col-pc-12">
                          <VueDateTimePicker
                            id="driver-license-issued-date"
                            :onlyDate="true"
                            :label="$t('civilian.driver-license-select-issue-date')"
                            format="YYYY-MM-DD"
                            formatted="YYYY-MM-DD"
                            v-model="civilian.driverLicense.issuedAt"
                            @input="debouncedUpdateDriverLicense('issuedAt', $event)"
                          ></VueDateTimePicker>
                        </div>
                      </div>
                      <div class="info">
                        <i
                          class="fas fa-question-circle"
                          :uk-tooltip="'title:' + $t('civilian.driver-license-issued-date') + ';' + 'pos: right'"
                        ></i>
                      </div>
                    </div>
                    <div class="row datepicker col-pc-12 col-mobile-12">
                      <p class="title">{{ $t('civilian.driver-license-expires') }}:</p>
                      <div class="choice col-pc-6">
                        <div class="custom-input primary col-pc-12">
                          <VueDateTimePicker
                            id="driver-license-expired-date"
                            :onlyDate="true"
                            :label="$t('civilian.driver-license-select-expiration-date')"
                            format="YYYY-MM-DD"
                            formatted="YYYY-MM-DD"
                            v-model="civilian.driverLicense.expiresAt"
                            @input="debouncedUpdateDriverLicense('expiresAt', $event)"
                            :showFromNow="true"
                          ></VueDateTimePicker>
                        </div>
                      </div>
                      <div class="info">
                        <i
                          class="fas fa-question-circle"
                          :uk-tooltip="'title:' + $t('civilian.driver-license-expiring-date') + ';' + 'pos: right'"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="license-name">
                    <div class="line"></div>
                    <p>{{ $t('civilian.fishing-license') }}</p>
                    <div class="line"></div>
                  </div>
                  <div class="coming-soon">
                    <p>{{ $t('civilian.coming-soon') }}</p>
                  </div>
                </div>
                <div class="column">
                  <div class="license-name">
                    <div class="line"></div>
                    <p>{{ $t('civilian.firearms-license') }}</p>
                    <div class="line"></div>
                  </div>
                  <div class="coming-soon">
                    <p>{{ $t('civilian.coming-soon') }}</p>
                  </div>
                </div>
                <div class="column">
                  <div class="license-name">
                    <div class="line"></div>
                    <p>{{ $t('civilian.boat-license') }}</p>
                    <div class="line"></div>
                  </div>
                  <div class="coming-soon">
                    <p>{{ $t('civilian.coming-soon') }}</p>
                  </div>
                </div>
                <div class="column">
                  <div class="license-name">
                    <div class="line"></div>
                    <p>{{ $t('civilian.pilot-license') }}</p>
                    <div class="line"></div>
                  </div>
                  <div class="coming-soon">
                    <p>{{ $t('civilian.coming-soon') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="vehicles col-all-12"
            v-if="currentTab === '#vehicles'"
          >
            <div class="table">
              <div class="tr col-pc-8 add pc-only">
                <button
                  class="secondary"
                  @click="addNewVehicle()"
                  :disabled="$store.getters['subscriptionPlanEnforcement/hasReachedMaxVehicles']"
                  :uk-tooltip="
                    $store.getters['subscriptionPlanEnforcement/hasReachedMaxVehicles']
                      ? `title: ${$t('civilian.has-reached-max-vehicles')}; cls: uk-active`
                      : `title: ; cls: col-display-none`
                  "
                >
                  <i class="fas fa-plus"></i>
                  <span>{{ $t('civilian.vehicle') }}</span>
                </button>
                <SinglePopup
                  :title="isEditingVehicle ? $t('civilian.edit-vehicle') : $t('civilian.new-vehicle')"
                  name="vehicle-popup"
                  class="default"
                  :adaptive="true"
                  height="80%"
                  top="50%"
                  :clickToClose="false"
                  :active="isVehicleOpen"
                >
                  <div class="form-box col-pc-12 col-mobile-11 noselect">
                    <div class="form col-pc-10">
                      <div class="image">
                        <p class="title">{{ $t('civilian.image') }}</p>
                        <div class="image-uploader secondary">
                          <div
                            uk-form-custom="target: true"
                            class="uk-form-custom uk-first-column"
                          >
                            <input
                              type="file"
                              disabled="disabled"
                            />
                            <input
                              type="text"
                              :placeholder="$t('civilian.coming-soon')"
                              v-model="newVehicle.image"
                              disabled="disabled"
                              class="uk-input uk-form-width-medium"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="plate">
                        <p class="title">{{ $t('civilian.vehicle-plate') }}</p>
                        <CustomInput
                          class="custom-input primary line custom-height col-pc-12"
                          inputClasses="col-pc-12"
                          v-model="$v.newVehicle.plate.$model"
                          :model="$v.newVehicle.plate"
                          :error="fieldsError.plate"
                          type="text"
                          :placeholder="$t('civilian.vehicle-plate_placeholder')"
                        ></CustomInput>
                      </div>
                      <div class="model">
                        <p class="title">{{ $t('civilian.vehicle-model') }}</p>
                        <CustomInput
                          class="custom-input primary line custom-height col-pc-12"
                          inputClasses="col-pc-12"
                          v-model="$v.newVehicle.model.$model"
                          :model="$v.newVehicle.model"
                          :error="fieldsError.model"
                          :placeholder="$t('civilian.vehicle-model_placeholder')"
                          type="text"
                        ></CustomInput>
                      </div>
                      <div class="color">
                        <p class="title">{{ $t('civilian.vehicle-color') }}</p>
                        <vSelect
                          v-model="newVehicle.color"
                          :reduce="color => color.value"
                          class="custom-select primary line col-pc-12"
                          :class="{ orange: fieldsError.color }"
                          :clearable="false"
                          :options="[
                            { label: $t('car-color-attribute.BLACK'), value: 'BLACK' },
                            { label: $t('car-color-attribute.BLUE'), value: 'BLUE' },
                            { label: $t('car-color-attribute.BROWN'), value: 'BROWN' },
                            { label: $t('car-color-attribute.YELLOW'), value: 'YELLOW' },
                            { label: $t('car-color-attribute.ORANGE'), value: 'ORANGE' },
                            { label: $t('car-color-attribute.PURPLE'), value: 'PURPLE' },
                            { label: $t('car-color-attribute.RED'), value: 'RED' },
                            { label: $t('car-color-attribute.GREEN'), value: 'GREEN' },
                            { label: $t('car-color-attribute.GREY'), value: 'GREY' },
                            { label: $t('car-color-attribute.WHITE'), value: 'WHITE' },
                            { label: $t('car-color-attribute.UNKNOWN'), value: 'UNKNOWN' }
                          ]"
                        ></vSelect>
                      </div>
                      <div class="insurance">
                        <p class="title">{{ $t('civilian.vehicle-insurance') }}</p>
                        <vSelect
                          v-model="newVehicle.insurance"
                          :reduce="insurance => insurance.value"
                          class="custom-select primary col-pc-12"
                          :class="{
                            success: newVehicle.insurance == 'VALID',
                            danger: ['EXPIRED', 'REVOKED', 'NONE'].includes(newVehicle.insurance),
                            orange: fieldsError.insurance
                          }"
                          :clearable="false"
                          :options="[
                            { label: $t('car-insurance-attribute.VALID'), value: 'VALID' },
                            { label: $t('car-insurance-attribute.EXPIRED'), value: 'EXPIRED' },
                            { label: $t('car-insurance-attribute.REVOKED'), value: 'REVOKED' },
                            { label: $t('car-insurance-attribute.NONE'), value: 'NONE' }
                          ]"
                        ></vSelect>
                      </div>
                      <div class="registration">
                        <p class="title">{{ $t('civilian.vehicle-registration') }}</p>
                        <vSelect
                          v-model="newVehicle.registration"
                          :reduce="registration => registration.value"
                          class="custom-select primary col-pc-12"
                          :class="{
                            success: newVehicle.registration == 'VALID',
                            warning: newVehicle.registration === 'SUSPENDED',
                            danger: ['EXPIRED', 'REVOKED', 'NONE'].includes(newVehicle.registration),
                            orange: fieldsError.registration
                          }"
                          :clearable="false"
                          :options="[
                            { label: $t('car-registration-attribute.VALID'), value: 'VALID' },
                            { label: $t('car-registration-attribute.EXPIRED'), value: 'EXPIRED' },
                            { label: $t('car-registration-attribute.SUSPENDED'), value: 'SUSPENDED' },
                            { label: $t('car-registration-attribute.REVOKED'), value: 'REVOKED' },
                            { label: $t('car-registration-attribute.NONE'), value: 'NONE' }
                          ]"
                        ></vSelect>
                      </div>
                      <!-- <div class="wanted">
                      <p class="title">Wanted Status</p>
                      <toggle-button v-model="newVehicle.wanted" :labels="{checked: $t('yes'), unchecked: $t('no') }"></toggle-button>
                      </div>-->
                    </div>
                    <div class="add col-pc-10 col-mobile-12">
                      <button
                        class="secondary-full col-pc-12 col-mobile-12"
                        @click="commitVehicle()"
                        :disabled="this.$v.$invalid"
                      >
                        {{ isEditingVehicle ? $t('actions.save') : $t('actions.add') }}
                      </button>
                    </div>
                  </div>
                </SinglePopup>
              </div>
              <div
                v-if="civilian && civilian.vehicles && civilian.vehicles.length > 0"
                class="vehicle-boxes col-all-12"
              >
                <div
                  class="tr col-pc-8 col-mobile-12"
                  v-for="(vehicle, index) in civilian.vehicles"
                  :key="vehicle.id"
                >
                  <div class="columns col-pc-12 col-mobile-12">
                    <div class="column col-pc-6 col-smobile-12-important col-mobile-7">
                      <div class="image col-smobile-displaynone">
                        <img
                          src="//cdn.leafcad.com/images/other/ghost_car.png"
                          alt="unknown-car"
                        />
                      </div>
                      <div class="buttons col-smobile-12">
                        <button
                          class="secondary-full col-pc-6 pc-only edit"
                          @click="editVehicule(index)"
                        >
                          {{ $t('actions.edit') }}
                        </button>
                        <button
                          class="danger-full col-pc-6 col-mobile-12 delete"
                          @click="deleteVehicule(index)"
                        >
                          {{ $t('actions.delete') }}
                        </button>
                      </div>
                    </div>
                    <div class="column col-pc-6 col-mobile-12">
                      <div class="infos">
                        <div class="plate row">
                          <h2>{{ vehicle.plate }}</h2>
                        </div>
                        <div class="text">
                          <div class="model row">
                            <p class="title">{{ $t('civilian.vehicle-model') }}:</p>
                            <p>{{ vehicle.model }}</p>
                          </div>
                          <div class="color row">
                            <p class="title">{{ $t('civilian.vehicle-color') }}:</p>
                            <p>{{ $t('car-color-attribute')[vehicle.color] }}</p>
                          </div>
                          <div class="insurance row">
                            <p class="title">{{ $t('civilian.vehicle-insurance') }}:</p>
                            <p
                              class="text"
                              :class="{
                                success: vehicle.insurance == 'VALID',
                                warning: vehicle.insurance === 'SUSPENDED',
                                danger: ['EXPIRED', 'REVOKED', 'NONE'].includes(vehicle.insurance)
                              }"
                            >
                              {{ $t('car-insurance-attribute')[vehicle.insurance] }}
                            </p>
                          </div>
                          <div class="registration row">
                            <p class="title">{{ $t('civilian.vehicle-registration') }}:</p>
                            <p
                              class="text"
                              :class="{
                                success: vehicle.registration == 'VALID',
                                warning: vehicle.registration === 'SUSPENDED',
                                danger: ['EXPIRED', 'REVOKED', 'NONE'].includes(vehicle.registration)
                              }"
                            >
                              {{ $t('car-registration-attribute')[vehicle.registration] }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="icon">
                      <div class="wanted" v-if="vehicle.wanted === true">
                        <i class="fas fa-exclamation-triangle" v-if="vehicle.wanted === true"></i>
                        <h3>Wanted</h3>
                      </div>
                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <p>{{ $t('civilian.no-vehicles') }}</p>
              </div>
            </div>
          </div>
          <div
            class="firearms col-pc-12"
            v-if="currentTab === '#firearms'"
          ></div>
          <div
            class="crimes col-pc-12"
            v-if="currentTab === '#crimes' && civilian"
          >
            <div class="table">
              <div
                class="tr col-pc-8 add pc-only"
                v-if="$store.state.selection.community.community.settings.civilianCanManageCriminalRecord"
              >
                <button
                  class="secondary"
                  @click="$store.dispatch('popup/toggle', { page: 'civilian', popup: 'arrestation' })"
                >
                  <i class="fas fa-plus"></i>
                  <span>{{ $t('civilian.arrestation') }}</span>
                </button>
                <button
                  class="secondary"
                  @click="$store.dispatch('popup/toggle', { page: 'civilian', popup: 'violation' })"
                >
                  <i class="fas fa-plus"></i>
                  <span>{{ $t('civilian.citation') }}</span>
                </button>
              </div>
              <div v-if="
                  civilian.arrestations &&
                    civilian.citations &&
                    (civilian.arrestations.length > 0 || civilian.citations.length > 0)
                ">
                <div
                  class="tr arrestation col-pc-8 col-mobile-12"
                  v-for="arrestation in civilian.arrestations"
                  :key="arrestation.id + '-arrestation'"
                >
                  <div class="row subtitle">
                    <div class="column type">
                      <p>{{ $t('civilian.arrestation') }}</p>
                    </div>
                    <div class="column date">
                      <p>{{ moment(arrestation.arrestationDate).format('YYYY-MM-DD HH:mm:ss') }}</p>
                      <!-- <div class="edit col-mobile-displaynone">
                      <button class="warning-full">
                        <i class="fas fa-edit"></i>
                      </button>
                      </div>-->
                      <div class="delete">
                        <button
                          v-if="$store.state.selection.community.community.settings.civilianCanManageCriminalRecord"
                          class="danger-full"
                          @click="deleteArrestation(arrestation.id)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="td offences col-pc-12 col-mobile-12">
                      <div class="th table-subtitle col-all-12">
                        <div class="col-all-12 uk-text-left">{{ $t('civilian.description') }}</div>
                        <div class="col-all-12 uk-text-center">{{ $t('civilian.act') }}</div>
                        <div class="col-all-12 uk-text-right">{{ $t('civilian.charge') }}</div>
                      </div>
                      <div
                        class="offence col-pc-12 col-mobile-12"
                        v-for="offence in arrestation.offences"
                        :key="offence.id + '-arrestation-offence'"
                      >
                        <div class="reason">{{ offence.description }}</div>
                        <div class="act-number">{{ offence.act }}</div>
                        <div class="charge">
                          {{ $t('charge-status.' + offence.chargeStatus)
                          }}{{
                            offence.chargeStatus !== 'NOT_GUILTY' && offence.chargeStatus !== 'PENDING'
                              ? `, ${offence.chargeTime} ${$t('timeframe.' + offence.chargeTimeFrame)} ${$t(
                                  'timeframe.of'
                                )} ${$t(offence.chargeType)}`
                              : ''
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="tr traffic-citation col-pc-8 col-mobile-12"
                  v-for="citation in civilian.citations"
                  :key="citation.id + '-trafficCitation'"
                >
                  <div class="row subtitle">
                    <div class="column type">
                      <p>{{ citation.vehicle ? $t('civilian.traffic-citation') : $t('civilian.citation') }}</p>
                    </div>
                    <div class="column date">
                      <p>{{ moment(citation.violationDate).format('YYYY-MM-DD HH:mm:ss') }}</p>
                      <!-- <div class="edit col-mobile-displaynone">
                      <button class="warning-full">
                        <i class="fas fa-edit"></i>
                      </button>
                      </div>-->
                      <div
                        v-if="$store.state.selection.community.community.settings.civilianCanManageCriminalRecord"
                        class="delete"
                        @click="deleteCitation(citation.id)"
                      >
                        <button class="danger-full">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="td offences col-pc-12 col-mobile-12">
                      <div class="th table-subtitle col-all-12">
                        <div class="col-all-12 uk-text-left">{{ $t('civilian.description') }}</div>
                        <div class="col-all-12 uk-text-center">{{ $t('civilian.act') }}</div>
                        <div class="col-all-12 uk-text-right">{{ $t('civilian.fine') }}</div>
                      </div>
                      <div
                        class="offence col-pc-12 col-mobile-12"
                        v-for="offence in citation.offences"
                        :key="offence.id + '-trafficCitation-offence'"
                      >
                        <div class="reason">{{ offence.description }}</div>
                        <div class="act-number">{{ offence.act }}</div>
                        <div class="fine">
                          <div v-if="!offence.fine">{{ $t('civilian.warning') }}</div>
                          <div
                            v-else
                            class="col-f"
                          >
                            <div
                              v-if="$route.params.lang === 'en'"
                              class="currency-before"
                            >$</div>
                            {{ offence.fine }}
                            <div
                              v-if="$route.params.lang === 'fr'"
                              class="currency-after"
                            >$</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <p>{{ $t('civilian.no-crimes') }}</p>
              </div>
            </div>
          </div>
          <div
            class="edit-civilian col-pc-12 col-mobile-12"
            v-if="currentTab === '#edit' && civilian"
          >
            <div class="form col-lgpc-5 col-mdpc-7 col-mobile-11">
              <div class="first-name">
                <p class="title">{{ $t('civilian.first-name') }}</p>
                <CustomInput
                  class="custom-input line primary col-all-12"
                  inputClasses="col-all-12"
                  type="text"
                  disabled
                  v-model.trim="$v.civilian.firstName.$model"
                  :model="$v.civilian.firstName"
                  :error="fieldsError.firstName"
                ></CustomInput>
              </div>
              <div class="last-name">
                <p class="title">{{ $t('civilian.last-name') }}</p>
                <CustomInput
                  class="custom-input line primary col-all-12"
                  inputClasses="col-all-12"
                  type="text"
                  disabled
                  v-model.trim="$v.civilian.lastName.$model"
                  :model="$v.civilian.lastName"
                  :error="fieldsError.lastName"
                ></CustomInput>
              </div>
              <div class="dob">
                <p class="title">{{ $t('civilian.date-of-birth') }}</p>
                <VueDateTimePicker
                  :onlyDate="true"
                  format="YYYY-MM-DD"
                  formatted="LL"
                  v-model="$v.civilian.birthDate.$model"
                  :error="!!fieldsError.birthDate"
                  :showFromNow="true"
                  :fromNowFormatter="
                    value => '(' + moment().diff(moment(value), 'years') + ' ' + $t('civilian.years-old') + ')'
                  "
                  @cleared="
                    () => {
                      $v.civilian.birthDate.$model = $store.state.selection.character.birthDate
                    }
                  "
                ></VueDateTimePicker>
              </div>
              <div class="gender">
                <p class="title">{{ $t('civilian.gender') }}</p>
                <vSelect
                  v-model="$v.civilian.gender.$model"
                  :reduce="gender => gender.value"
                  class="custom-select primary line col-all-12"
                  :class="{ orange: fieldsError.gender }"
                  :clearable="false"
                  :options="[
                    { label: $t('gender-attribute.MALE'), value: 'MALE' },
                    { label: $t('gender-attribute.FEMALE'), value: 'FEMALE' }
                  ]"
                ></vSelect>
              </div>
              <div class="weight">
                <p class="title">{{ $t('civilian.weight') }}</p>
                <CustomInput
                  class="custom-input primary line col-all-12"
                  inputClasses="col-all-12"
                  type="number"
                  v-model.number="$v.civilian.weight.$model"
                  :model="$v.civilian.weight"
                  :error="fieldsError.weight"
                  step="0.01"
                ></CustomInput>
              </div>
              <div class="height">
                <p class="title">{{ $t('civilian.height') }}</p>
                <CustomInput
                  class="custom-input primary line col-all-12"
                  inputClasses="col-all-12"
                  type="number"
                  v-model.number="$v.civilian.height.$model"
                  :model="$v.civilian.height"
                  :error="fieldsError.height"
                  step="0.01"
                ></CustomInput>
              </div>
              <!-- <div class="address">
              <p class="title">{{ $t('civilian.address') }}</p>
              <div class="custom-input primary">
                <input type="text" v-model="civilian.address" />
              </div>
              </div>-->
              <div class="race">
                <p class="title">{{ $t('civilian.ethnicity') }}</p>
                <vSelect
                  v-model="$v.civilian.ethnicity.$model"
                  :reduce="ethnicity => ethnicity.value"
                  class="custom-select primary line col-pc-12"
                  :class="{ orange: fieldsError.ethnicity }"
                  :clearable="false"
                  :options="[
                    { label: $t('ethnic-group-attribute.HISPANIC'), value: 'HISPANIC' },
                    { label: $t('ethnic-group-attribute.NATIVE'), value: 'NATIVE' },
                    { label: $t('ethnic-group-attribute.ASIAN'), value: 'ASIAN' },
                    { label: $t('ethnic-group-attribute.BLACK'), value: 'BLACK' },
                    { label: $t('ethnic-group-attribute.WHITE'), value: 'WHITE' },
                    { label: $t('ethnic-group-attribute.UNKNOWN'), value: 'UNKNOWN' }
                  ]"
                ></vSelect>
              </div>
              <div class="eye-color">
                <p class="title">{{ $t('civilian.eye-color') }}</p>
                <vSelect
                  v-model="$v.civilian.eyeColor.$model"
                  :reduce="eyeColor => eyeColor.value"
                  class="custom-select primary line col-all-12"
                  :class="{ orange: fieldsError.eyeColor }"
                  :clearable="false"
                  :options="[
                    { label: $t('eye-color-attribute.BROWN'), value: 'BROWN' },
                    { label: $t('eye-color-attribute.BLUE'), value: 'BLUE' },
                    { label: $t('eye-color-attribute.GREEN'), value: 'GREEN' },
                    { label: $t('eye-color-attribute.AMBER'), value: 'AMBER' },
                    { label: $t('eye-color-attribute.OTHER'), value: 'OTHER' }
                  ]"
                ></vSelect>
              </div>
              <div class="hair-color">
                <p class="title">{{ $t('civilian.hair-color') }}</p>
                <vSelect
                  append-to-body
                  :calculate-position="withPopper"
                  v-model="$v.civilian.hairColor.$model"
                  :reduce="hairColor => hairColor.value"
                  class="custom-select primary line col-all-12 dropdown:line"
                  :class="{ orange: fieldsError.hairColor }"
                  :clearable="false"
                  :options="[
                    { label: $t('hair-color-attribute.AUBURN'), value: 'AUBURN' },
                    { label: $t('hair-color-attribute.BLACK'), value: 'BLACK' },
                    { label: $t('hair-color-attribute.BLOND'), value: 'BLOND' },
                    { label: $t('hair-color-attribute.BROWN'), value: 'BROWN' },
                    { label: $t('hair-color-attribute.REDHAIR'), value: 'REDHAIR' },
                    { label: $t('hair-color-attribute.WHITE'), value: 'WHITE' },
                    { label: $t('hair-color-attribute.BALD'), value: 'BALD' },
                    { label: $t('hair-color-attribute.OTHER'), value: 'OTHER' },
                    { label: $t('hair-color-attribute.UNKNOWN'), value: 'UNKNOWN' }
                  ]"
                ></vSelect>
              </div>
            </div>
            <div class="save col-lgpc-5 col-mdpc-7 col-mobile-11">
              <button
                class="secondary-full col-mobile-12"
                :class="{ spinner: isLoading }"
                :disabled="isLoading || $v.$invalid"
                @click="saveCivilian"
              >
                {{ $t('actions.save') }}
              </button>
              <!-- <button class="danger-full col-mobile-12" @click="deleteCivilian">{{ $t('actions.delete') }}</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-tour
      :name="this.$route.name"
      :steps="tourSteps"
      :callbacks="finishCallbackOnly"
      :options="translatedLabels"
    ></v-tour>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/civilian.less"></style>
<style lang="less" scoped src="@/assets/less/components/driver_license.less"></style>

<script>
import UnloadHelper from '@/mixins/UnloadHelper'
import DropdownPositionCalculator from '@/mixins/DropdownPositionCalculator'
import EnforcePermissionsForCommunity from '@/mixins/EnforcePermissionsForCommunity'
import TourManager from '@/mixins/TourManager'
import InformationBanner from '@/components/InformationBanner'
import SinglePopup from '@/components/SinglePopup'
import Arrestation from '@/components/Arrestation'
import Violation from '@/components/Violation'
import { last, debounce } from 'lodash'
// import validationMessage from '@/validationMapping'
import { requiredIf, decimal, helpers } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

const alphaDash = helpers.regex('alpha', /^[a-zA-Z ,.'-]*$/)

export default {
  name: 'civilian',
  mixins: [UnloadHelper, DropdownPositionCalculator, EnforcePermissionsForCommunity, TourManager],
  components: {
    InformationBanner,
    SinglePopup,
    Arrestation,
    Violation
  },
  data() {
    return {
      version: 'Open Beta',
      fieldsError: {},
      isLoading: false,
      isVehicleOpen: false,
      newVehicle: {
        plate: '',
        model: '',
        color: 'BLACK',
        insurance: 'VALID',
        registration: 'VALID'
      },
      initialNewVehicle: {},
      isEditingVehicle: false,
      licenses: {
        driver: {
          classes: [],
          conditions: []
        },
        fishing: {
          classes: [],
          restrictions: []
        }
      },
      civilian: null,
      currentTab: this.$route.hash || '#licenses',
      tourSteps: [
        {
          target: '.v-step-license-button',
          content: this.$t('tour.civilian.license-button'),
          params: {
            enableScrolling: false
          }
        },
        {
          target: '.v-step-vehicle-button',
          content: this.$t('tour.civilian.vehicle-button'),
          params: {
            enableScrolling: false
          }
        },
        {
          target: '.v-step-crimes-button',
          content: this.$t('tour.civilian.crimes-button'),
          params: {
            enableScrolling: false
          }
        },
        {
          target: '.v-step-edit-button',
          content: this.$t('tour.civilian.edit-button'),
          params: {
            enableScrolling: false
          }
        },
        {
          target: '.v-step-change-button',
          content: this.$t('tour.civilian.change-button'),
          params: {
            enableScrolling: false
          }
        },
        {
          target: '.v-step-logout-button',
          content: this.$t('tour.civilian.logout-button'),
          params: {
            enableScrolling: false
          }
        }
      ]
    }
  },
  validations: {
    newVehicle: {
      model: {
        required: requiredIf(function() {
          return this.currentTab === '#vehicles'
        })
      },
      plate: {
        required: requiredIf(function() {
          return this.currentTab === '#vehicles'
        })
      },
      color: {
        required: requiredIf(function() {
          return this.currentTab === '#vehicles'
        })
      },
      insurance: {
        required: requiredIf(function() {
          return this.currentTab === '#vehicles'
        })
      },
      registration: {
        required: requiredIf(function() {
          return this.currentTab === '#vehicles'
        })
      }
    },
    civilian: {
      firstName: {
        required: requiredIf(function() {
          return this.currentTab === '#edit'
        }),
        alphaDash
      },
      lastName: {
        required: requiredIf(function() {
          return this.currentTab === '#edit'
        }),
        alphaDash
      },
      birthDate: {
        required: requiredIf(function() {
          return this.currentTab === '#edit'
        })
      },
      height: {
        required: requiredIf(function() {
          return this.currentTab === '#edit'
        }),
        decimal
      },
      weight: {
        required: requiredIf(function() {
          return this.currentTab === '#edit'
        }),
        decimal
      },
      ethnicity: {
        required: requiredIf(function() {
          return this.currentTab === '#edit'
        })
      },
      gender: {
        required: requiredIf(function() {
          return this.currentTab === '#edit'
        })
      },
      hairColor: {
        required: requiredIf(function() {
          return this.currentTab === '#edit'
        })
      },
      eyeColor: {
        required: requiredIf(function() {
          return this.currentTab === '#edit'
        })
      }
    }
  },
  apollo: {
    civilian: {
      query: require('@/graphql/civilian/fetchCivilianInfos.gql'),
      variables() {
        return {
          civilianId: this.$store.state.selection.character.id
        }
      },
      update(data) {
        this.$store.dispatch('subscriptionPlanEnforcement/updateVehiclesCount', data.civilian.vehicles.length)

        return data.civilian
      },
      subscribeToMore: {
        document: require('@/graphql/civilian/subscribeToCivilianInfos.gql'),
        variables() {
          return {
            civilianId: this.$store.state.selection.character.id
          }
        }
      }
    }
  },
  mounted() {
    this.$store
      .dispatch('civilian/fetchCommunityDriverLicenseConfig')
      .then(response => {
        this.licenses.driver.classes = response.driverLicenseClasses
        this.licenses.driver.conditions = response.driverLicenseConditions
      })
      .catch(error => {
        this.$store.dispatch('errorManager/showError', { error: error })
      })

    this.initialNewVehicle = Object.assign({}, this.newVehicle)
  },
  watch: {
    // whenever the hash changes, this function will run
    '$route.hash': function(newHash, oldHash) {
      this.currentTab = newHash
    },
    currentTab: function(newHash) {
      this.updateHash(newHash)
    }
  },
  computed: {
    selectedCivilian() {
      return this.$store.state.selection.character
    }
  },
  methods: {
    ...mapGetters('auth', ['checkCurrentPermissions']),
    updateHash(newHash) {
      if (!newHash.includes('#')) {
        newHash = '#' + newHash
      }

      this.$router.push({ hash: newHash })
    },
    showNewArrestation() {
      this.$modal.show('new-arrestation')
    },
    showNewTrafficCitation() {
      this.$modal.show('new-traffic-citation')
    },
    commitVehicle: function(index) {
      if (this.isEditingVehicle) {
        let sanitizedNewVehicle = Object.assign({}, this.newVehicle)
        delete sanitizedNewVehicle.id
        delete sanitizedNewVehicle.index
        delete sanitizedNewVehicle.__typename

        this.$store
          .dispatch('civilian/updateCurrentCivilian', {
            vehicles: { update: { where: { id: this.newVehicle.id }, data: sanitizedNewVehicle } }
          })
          .then(response => {
            this.civilian.vehicles[this.newVehicle.index] = this.newVehicle
            this.newVehicle = {}
            this.$modal.hide('vehicle-popup')

            this.$notify({
              type: 'success',
              title: this.$t('notifications.save.title'),
              text: this.$t('notifications.save.text')
            })
          })
          .catch(error => {
            this.$store.dispatch('errorManager/showError', { error: error })
          })
      } else {
        this.$store
          .dispatch('civilian/updateCurrentCivilian', { vehicles: { create: this.newVehicle } })
          .then(response => {
            this.newVehicle.id = last(response.vehicles).id
            this.civilian.vehicles.push(this.newVehicle)
            this.newVehicle = {}
            this.$modal.hide('vehicle-popup')

            this.$notify({
              type: 'success',
              title: this.$t('notifications.save.title'),
              text: this.$t('notifications.save.text')
            })
          })
          .catch(error => {
            this.$store.dispatch('errorManager/showError', { error: error })
          })
      }
    },
    addNewVehicle() {
      if (this.isEditingVehicle) {
        this.isEditingVehicle = false
        this.newVehicle = Object.assign({}, this.initialNewVehicle)
      }
      this.$modal.show('vehicle-popup')
    },
    editVehicule: function(index) {
      this.isEditingVehicle = true
      this.newVehicle = Object.assign({}, this.civilian.vehicles[index])
      this.newVehicle.index = index
      this.$modal.show('vehicle-popup')
    },
    deleteVehicule: function(index) {
      this.$swal({
        title: this.$t('civilian.are-you-sure'),
        text: this.$t('civilian.you-wont-be-able-to-revert'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('actions.delete'),
        cancelButtonText: this.$t('actions.cancel')
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('civilian/updateCurrentCivilian', {
              vehicles: { delete: { id: this.civilian.vehicles[index].id } }
            })
            .then(response => {
              this.civilian.vehicles.splice(index, 1)

              this.$notify({
                type: 'success',
                title: this.$t('notifications.save.title'),
                text: this.$t('notifications.save.text')
              })
            })
            .catch(error => {
              this.$store.dispatch('errorManager/showError', { error: error })
            })
        }
      })
    },
    deleteCivilian() {
      this.$swal({
        title: this.$t('civilian.are-you-sure'),
        text: this.$t('civilian.you-wont-be-able-to-revert'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('actions.delete'),
        cancelButtonText: this.$t('actions.cancel')
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('civilian/deleteCurrentCivilian')
            .then(response => {
              this.$router.push({ name: 'roles', query: { skipLeaveConfirmation: true } })

              this.$notify({
                type: 'success',
                title: this.$t('notifications.save.title'),
                text: this.$t('notifications.save.text')
              })
            })
            .catch(error => {
              this.$store.dispatch('errorManager/showError', { error: error })
            })
        }
      })
    },
    debouncedUpdateDriverLicense: debounce(function(field, data) {
      this.updateDriverLicense(field, data)
    }, 500),
    updateDriverLicense: function(field, data) {
      if (field === 'classes' || field === 'conditions') {
        if (data.checked) {
          data = { connect: { id: data.id } }
        } else {
          data = { disconnect: { id: data.id } }
        }
      }

      this.$store
        .dispatch('civilian/updateDriverLicense', { civilianId: this.civilian.id, [field]: data })
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    saveCivilian: function() {
      this.isLoading = true

      // This might not be super quick when there are multiple cars and crimes
      const civilian = Object.assign({}, this.civilian)
      delete civilian.vehicles
      delete civilian.arrestations
      delete civilian.citations

      civilian.weight = parseFloat(civilian.weight)
      civilian.height = parseFloat(civilian.height)

      this.$store
        .dispatch('civilian/updateCurrentCivilian', civilian)
        .then(response => {
          this.isLoading = false

          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })
        })
        .catch(error => {
          this.isLoading = false
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    deleteCitation(citationId) {
      const civilian = {
        civilianId: this.civilian.id,
        citations: {
          delete: {
            id: citationId
          }
        }
      }

      this.$swal({
        title: this.$t('civilian.are-you-sure'),
        text: this.$t('civilian.you-wont-be-able-to-revert'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('actions.delete'),
        cancelButtonText: this.$t('actions.cancel')
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('civilian/updateCivilian', civilian)
            .then(response => {
              this.$notify({
                type: 'success',
                title: this.$t('notifications.save.title'),
                text: this.$t('notifications.save.text')
              })
            })
            .catch(error => {
              this.$store.dispatch('errorManager/showError', { error: error })
            })
        }
      })
    },
    deleteArrestation(arrestationId) {
      const civilian = {
        civilianId: this.civilian.id,
        arrestations: {
          delete: {
            id: arrestationId
          }
        }
      }

      this.$swal({
        title: this.$t('civilian.are-you-sure'),
        text: this.$t('civilian.you-wont-be-able-to-revert'),
        type: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: this.$t('actions.delete'),
        cancelButtonText: this.$t('actions.cancel')
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('civilian/updateCivilian', civilian)
            .then(response => {
              this.$notify({
                type: 'success',
                title: this.$t('notifications.save.title'),
                text: this.$t('notifications.save.text')
              })
            })
            .catch(error => {
              this.$store.dispatch('errorManager/showError', { error: error })
            })
        }
      })
    }
  }
}
</script>
